<script>
import { hasModule } from "@/mixins/ModulesGuards/AbstractModuleGuard";

export default {
	name: 'WModule',
	functional: true,
	props: {
		name: {
			required: true,
			type: [String, Array],
			validator: (value) => value && (typeof value === 'string' || (Array.isArray(value) && value.every(item => typeof item === 'string')))
		}
	},
	render(_, { props, children }) {
		if (props.name && hasModule({ moduleName: props.name })) {
			return children;
		}
		return null;
	}
}
</script>
